<template>
  <div class="container"
       :class="{ 
         'container--padded': padded,
         'container--fluid': fluid,
       }"
  >
    <slot />
  </div>
</template>

<script>
export default {
  props: {
    padded: { type: Boolean, default: false },
    fluid: { type: Boolean, default: false },
  }
}
</script>

<style scoped lang="scss">
.container {
  &--padded {
    padding: 0 1.5rem;
  }

  &:deep(.row) {
    &:last-child {
      &:deep(.column:last-child) {
        margin-bottom: 0;
      }
    }
  }
}
</style>
